import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header/header";
import Footer from "./footer/footer";

const BlogLayout = ({ children, imageUrl }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} imageUrl={imageUrl} />
      <main className="template-blog">{children}</main>
      <Footer />
    </>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;

import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/base/blog-layout";
import SEO from "../components/base/seo";
import "../styles/pages/_blog.scss";

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO
        title="Marketing Insights | Blog"
        description="Read Marketing Related Insights, Ideas, and Interesting Tidbits from The Simple Department."
      />
      <section className="page-heading">
        <div className="container">
          <h1>BLOG</h1>
        </div>
      </section>
      <section>
        <div className="container all-blogs">
          {edges.map(({ node }, i) =>
            node.frontmatter.type === "blog" ? (
              <div key={i} className="single-blog-wrapper">
                <Link className="single-blog-inner" to={node.frontmatter.path}>
                  <img
                    src={
                      node.frontmatter.featuredImage.childImageSharp.fluid.src
                    }
                    alt={node.frontmatter.title}
                  />
                  <p className="single-blog-short-info">
                    {node.frontmatter.categories.map((cat, index) => {
                      if (index === node.frontmatter.categories.length - 1) {
                        return cat;
                      } else {
                        return cat + ", ";
                      }
                    })}
                  </p>
                  <p className="single-blog-title">{node.frontmatter.title}</p>
                  <p className="single-blog-excert">
                    {node.frontmatter.excerpt}
                  </p>
                  <Link to={node.frontmatter.path} className="blue-arrow-btn">
                    READ MORE
                  </Link>
                </Link>
              </div>
            ) : null
          )}
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 150)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            type
            path
            title
            excerpt
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
